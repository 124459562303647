<template>
  <div class="panel-group rapport">
    <div class="panel panel-default">
      <div class="panel-heading">
        <a data-toggle="collapse" v-bind:href="getCollapse(rapport.uuid)">
          Rapport - {{ getDate(rapport.dateRapport) }}
        </a>
      </div>
      <div
        v-bind:id="getCollapse(rapport.uuid, true)"
        class="panel-collapse collapse"
      >
        <div class="panel-body">
          <form v-on:submit.prevent="onSubmit">
            <div class="form-group">
              <label for="videoInput">Vidéo(s) :</label>
              <input
                type="number"
                min="0"
                class="form-control"
                id="videoInput"
                v-model.lazy="rapport.video"
              />
            </div>
            <div class="form-group">
              <label for="perioInput">Revue(s) :</label>
              <input
                type="number"
                min="0"
                class="form-control"
                id="perioInput"
                v-model.lazy="rapport.perio"
              />
            </div>
            <div class="form-group">
              <label for="brochureInput">Brochure(s) :</label>
              <input
                type="number"
                min="0"
                class="form-control"
                id="brochureInput"
                v-model.lazy="rapport.brochure"
              />
            </div>
            <div class="form-group">
              <label for="livreInput">Livre(s) :</label>
              <input
                type="number"
                min="0"
                class="form-control"
                id="livreInput"
                v-model.lazy="rapport.livre"
              />
            </div>
            <div class="form-group">
              <label for="tractInput">Tract(s) :</label>
              <input
                type="number"
                min="0"
                class="form-control"
                id="tractInput"
                v-model.lazy="rapport.tract"
              />
            </div>
            <div class="form-group">
              <label for="remarqueInput">Remarque(s) :</label>
              <textarea
                class="form-control"
                id="remarqueInput"
                rows="3"
                v-model.lazy.trim="rapport.remarque"
              ></textarea>
            </div>
            <transition name="fade">
              <div
                class="alert"
                v-bind:class="[isError ? 'alert-danger' : 'alert-success']"
                v-if="messageAlert"
              >
                {{ messageAlert }}
              </div>
            </transition>
            <button
              class="btn btn-success btn-md center-block button-vue"
              :disabled="isSave"
            >
              <icon
                class="wait"
                name="spinner"
                spin
                v-bind:class="[isSave ? '' : 'hide']"
              />
              Enregistrer
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-awesome/icons";
import Icon from "vue-awesome/components/Icon";
import DateUtils from "../utils/dateUtils";

export default {
  name: "RapportItem",

  data() {
    return {
      rapport: {
        uuid: this.item.node.uuid,
        dateRapport: this.item.node.dateRapport,
        video: this.item.node.video,
        perio: this.item.node.perio,
        brochure: this.item.node.brochure,
        livre: this.item.node.livre,
        tract: this.item.node.tract,
        remarque: this.item.node.remarque,
      },
      isSave: false,
      isError: false,
      messageAlert: "",
    };
  },

  props: {
    item: Object,
  },

  components: {
    Icon,
  },

  methods: {
    onSubmit: function () {
      this.isSave = true;

      if (this.rapport.video === "") {
        this.rapport.video = 0;
      }

      if (this.rapport.perio === "") {
        this.rapport.perio = 0;
      }

      if (this.rapport.brochure === "") {
        this.rapport.brochure = 0;
      }

      if (this.rapport.livre === "") {
        this.rapport.livre = 0;
      }

      if (this.rapport.tract === "") {
        this.rapport.tract = 0;
      }

      this.rapport.video = parseInt(this.rapport.video);
      this.rapport.perio = parseInt(this.rapport.perio);
      this.rapport.brochure = parseInt(this.rapport.brochure);
      this.rapport.livre = parseInt(this.rapport.livre);
      this.rapport.tract = parseInt(this.rapport.tract);

      this.$emit("onSubmit", this, this.rapport);
    },

    clear: function () {
      this.isSave = false;
      this.messageAlert = "Enregistrement réussi.";

      setTimeout(() => {
        this.messageAlert = "";
      }, 3000);
    },

    error: function () {
      this.isSave = false;
      this.isError = true;
      this.messageAlert = "Erreur lors de l'enregistrement !";

      setTimeout(() => {
        this.isError = false;
        this.messageAlert = "";
      }, 3000);
    },

    getDate: function (dateDay) {
      return new DateUtils(dateDay).getDateString();
    },

    getCollapse: function (index, isId = false) {
      let value = "#collapse" + index;

      if (isId) {
        value = "collapse" + index;
      }

      return value;
    },
  },
};
</script>
