<template>
  <div class="vue">
    <div class="container-vue container">
      <h1>Rapports</h1>
      <transition-group name="list-complete">
        <div
          class="list-complete-item"
          v-for="rapport in rapports"
          :key="rapport.node.uuid"
        >
          <item v-bind:item="rapport" v-on:onSubmit="onSaveRapport" />
        </div>
      </transition-group>
      <infinite-loading @infinite="getRapports" spinner="spiral">
        <template v-slot:no-more>
          <span></span>
        </template>
        <template v-slot:no-results>
          <div class="message">
            <h2>Pas de rapports.</h2>
          </div>
        </template>
        <template v-slot:error>
          <div class="message">
            <h2>Opps, something went wrong.</h2>
          </div>
        </template>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import Item from "@/components/RapportItem";
import Graphql from "../utils/graphQl";
import QueryUtils from "../utils/queries/rapportQuery";
import QueryMixin from "../utils/queryMixin";
import RedirectMixin from "../utils/redirectionMixin";

export default {
  name: "Rapport",

  mixins: [QueryMixin, RedirectMixin],

  title() {
    return `${this.title} - ${this.congregationName}`;
  },

  data() {
    return {
      rapports: [],
      cursorRapport: "",
      title: this.$store.state.title,
      token: this.$store.state.token,
      userUuid: this.$store.state.userUuid,
      congregationName: this.$store.state.congregationName,
    };
  },

  components: {
    Item,
  },

  methods: {
    getRapports: function ($state) {
      QueryUtils.setRead();
      QueryUtils.setVariables(
        this.getQueryVariables(
          "uuid",
          "proclamateurs/",
          this.userUuid,
          this.cursorRapport
        )
      );

      // Start GraphQL - Return Rapport
      new Graphql(this.token, QueryUtils).request().then((response) => {
        const result = response.data.data.proclamateur.rapports.edges;

        if (result.length > 0) {
          this.cursorRapport = result[result.length - 1].cursor;
          this.rapports.push(...result);

          $state.loaded();
        } else {
          $state.complete();
        }
      });
      // End GraphQL
    },

    onSaveRapport: function (rapportItem, rapport) {
      QueryUtils.setUpdate();
      QueryUtils.setVariables(this.setMutableVariables(rapport));

      // Start GraphQL - Mutation Rapport
      new Graphql(this.token, QueryUtils).request().then((response) => {
        if (
          typeof response.data.data.updateRapport.rapport.uuid !== "undefined"
        ) {
          rapportItem.clear();
        } else {
          rapportItem.error();
        }
      });
      // End GraphQL
    },

    setMutableVariables: function (rapport) {
      let variables = {
        id: "rapports/" + rapport.uuid,
      };

      for (const key in rapport) {
        variables[key] = rapport[key];
      }

      return this.getMutableContainer(variables, rapport.uuid);
    },
  },
};
</script>
