import QueryUtils from "../queryUtils";

const queries = [
  // Read
  `query GetRapports( $uuid: ID!, $cursor: String ) {
    proclamateur( id: $uuid ) {

      rapports( after: $cursor ) {
        edges {
          cursor
          
          node {
            dateRapport
            video
            perio
            brochure
            livre
            tract
            remarque
            uuid
          }
        }
      }
    }
  }`,
  // Update
  `mutation UpdateRapport( $entity: updateRapportInput! ) {
    updateRapport( input: $entity ) {
      rapport {
        uuid
      }
    }
  }`,
  // Delete
  ``,
];

export default new QueryUtils(queries);
